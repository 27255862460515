.App {
  max-width: 100%;
  text-align: center;
}

.pagination-button {
  margin: 8px;
  cursor: pointer;
}

.disabled-pagination-button {
  color: "#a3a3a3";
  margin: 8px;
}

.client-selector {
  margin-left: 20px;
}

.group-chip {
  width: fit-content;
  background-color: #333333;
  padding: 5px 20px;
  border-radius: 10px;
  color: #ffffff;
  margin: 6px;
}

.tab-status {
  cursor: pointer;
  width: 200px;
}

.tab-status-inactive {
  background: "#a3a3a3";
  width: 200px;
}

.list-group-item {
  padding: 6px;
}

.table-nonfluid {
  width: auto !important;
}

.wrapper {
  padding: 16px;
  width: 100%;
  background-color: #e3e3e3;
  min-height: 100vh;
  max-width: 2192px;
  display: flex;
  flex-direction: column;
  align-content: center;
}

.form-label {
  font-size: 0.7em;
}

.form-text-field {
  width: 100%;
}

.form-item {
  padding: 6px 10px;
  text-align: left;
  border: 1px dashed #a3a3a3;
  border-radius: 6px;
  width: 100%;
  max-width: 400px;
  justify-content: flex-start;
}
.form-button {
  width: 300px;
}

.daterangepicker .ranges li.active {
  background-color: #007bff;
}

.daterangepicker td.in-range {
  background-color: #b3e5fc;
}

.daterangepicker td.active,
.daterangepicker td.end-date,
.daterangepicker td.active:hover {
  background-color: #007bff;
}

.summary-stats {
  border-top: 1px dashed black;
}

.summary-row {
  padding: 8px;
  border-bottom: 1px dashed black;
}

.max-half-width {
  max-width: 47%;
  padding: 5px;
}

.dropzone-drag-active {
  outline: 5px dashed #b3e5fc;
  outline-offset: 2px;
  outline-width: medium;
}

.scorecard {
  padding: 0px 10px;
  background-color: white;
  border-radius: 6px;
  -webkit-box-shadow: 0px 2px 2px #3b3b3b;
  -moz-box-shadow: 0px 2px 2px #3b3b3b;
  box-shadow: 0px 2px 2px #3b3b3b;
}

.widget-wrapper {
  margin: 10px;
  background-color: white;
  border-radius: 6px;
  -webkit-box-shadow: 0px 2px 2px #3b3b3b;
  -moz-box-shadow: 0px 2px 2px #3b3b3b;
  box-shadow: 0px 2px 2px #3b3b3b;
}

.legend {
  font-size: 0.6em;
  margin: 10px;
  max-width: 30%;
}

#button-tooltip {
  white-space: pre-line;
}

.popover-header {
  background-color: #333333;
  color: #ffffff;
}

.popover-body {
  background-color: #666666;
  color: #ffffff;
}

.tooltip {
  position: absolute;
  background-color: #ffffff;
  padding: 5px;
  border-radius: 4px;
  border: 2px solid #000000;
}

.profile-pic {
  border-radius: 50%;
  width: 45px;
  height: 45px;
}

.nav-link {
  padding: 0px;
}
